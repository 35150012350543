import {
  Footer,
  IsLoggedInProvider,
  LinkProvider,
  ThemeProvider,
  Topbar,
} from '@genially/public-web-components';

import { useEffect, useState } from 'react';
import Camara from './assets/camara.png';
import type { ElementType } from 'react';
import Europe from './assets/footerx2.png';
import Innoxport from './assets/innoxport.png';
import { Link } from 'components/Link/Link';
import MegamenuContent from './MegamenuContent';
import { OneTap } from 'components/OneTap/OneTap';
import type { PageProps } from 'gatsby';
import { Seo } from 'components/Seo/Seo';
import configService from 'core/services/configService';
import { createLanguageDropdownOptions } from 'core/infrastructure/createLanguageDropdownOptions';
import { snowplowWrapper } from 'core/services/snowplow';
export type WrapperProps = PageProps<any, { node: { data: any } }>;

declare global {
  interface Window {
    Optanon?: {
      OnConsentChanged: (cb: (e: { detail: string[] }) => void) => void;
    };
  }
}

const WithLayout = (Component: ElementType): ElementType => {
  const wrapper = ({ pageContext, location }: WrapperProps) => {
    const { topbar, footer } =
      configService.data.languages[pageContext.node.data.content.langcode.value];

    topbar.desktop.topbarLinks.map((option: any, index: number) => {
      if (option.megamenu !== null) {
        topbar.desktop.topbarLinks[index].megamenu = option.megamenu;
        topbar.desktop.topbarLinks[index].megamenuRender = (
          <MegamenuContent content={option.megamenu} />
        );
      }
    });

    const content = pageContext.node.data.content;
    let firstCompBg =
      content.paragraphs && content.paragraphs.length > 0
        ? content?.paragraphs[0]?.entity?.behaviorSettings?.rowStyle?.background?.baseColor?.color
          ? content?.paragraphs[0]?.entity?.behaviorSettings?.rowStyle?.background?.baseColor?.color
          : 'rWhite'
        : 'rWhite';

    if (content.bundle === 'template_category') {
      firstCompBg =
        content?.header?.entity?.behaviorSettings?.rowStyle?.background?.baseColor.color;
    }
    const [scrolling, setScrolling] = useState(firstCompBg);

    useEffect(() => {
      const hasPerformanceCookies = (activeGroups: string | string[]): boolean => {
        return activeGroups.includes('C0003');
      };
      async function cookieConsent() {
        if (window) {
          window.Optanon &&
            window.Optanon.OnConsentChanged(function (e: any) {
              if (hasPerformanceCookies(e.detail)) {
                const sendPageView = () => {
                  // Snowplow tracking for all pages and all cases
                  snowplowWrapper.initTracker();
                  snowplowWrapper.sendPageViewEvent();
                };
                // Minimum delay for reactHelmet's requestAnimationFrame
                setTimeout(sendPageView, 32);
              }
            });
        }
      }
      cookieConsent();
    }, []);

    return (
      <ThemeProvider>
        <LinkProvider customLink={Link}>
          <IsLoggedInProvider>
            <Seo content={pageContext.node.data.content} />
            <Topbar
              url={location.pathname}
              setScrolling={setScrolling}
              firstBg={firstCompBg}
              brandLink={topbar.desktop.linksAndButtons.brandLink}
              links={topbar.desktop.topbarLinks}
              loggedInButtons={topbar.desktop.linksAndButtons.buttonPrimaryLoggedIn}
              buttons={[
                topbar.desktop.linksAndButtons.buttonStroke,
                topbar.desktop.linksAndButtons.buttonPrimary,
              ]}
              languageOptions={createLanguageDropdownOptions(
                topbar.desktop.listLanguageOptions,
                pageContext.node.data.content
              )}
            />
            {process.env.NODE_ENV == 'production' && <OneTap />}
            <Component content={pageContext.node.data.content} />
            <Footer
              brandLink={footer.brandLink}
              lang={pageContext.node.data.content.langcode.value}
              lists={footer.lists}
              title={footer.title}
              anonymousButton={footer.anonymousButton}
              loggedInButton={footer.loggedInButton}
              socialMediaLinks={footer.socialMediaLinks}
              languageOptions={createLanguageDropdownOptions(
                footer.languageOptions,
                pageContext.node.data.content
              )}
              europeLogo={Europe}
              innoxportLogo={Innoxport}
              camaraLogo={Camara}
              europeText={footer.europeText}
              europeText2={footer.europeText2}
              europeText3={footer.europeText3}
              europeText4={footer.europeText4}
              europeText5={footer.europeText5}
              europeText6={footer.europeText6}
              europeText7={footer.europeText7}
              bottomLinks={footer.bottomLinks}
              bottomBrandText={footer.bottomBrandText}
            />
          </IsLoggedInProvider>
        </LinkProvider>
      </ThemeProvider>
    );
  };
  return wrapper;
};

export default WithLayout;
