import type { FC } from 'react';
import { ModalContainer } from '@genially/public-web-components';
import { ParagraphSection } from 'components/ParagraphSection';

interface ParagraphModalProps {
  children?: any;
}

export const ParagraphModal: FC<ParagraphModalProps> = ({ children }) => {
  return (
    <ModalContainer>
      {children && (
        <>
          <ParagraphSection key={0} {...children[0]?.content} />
          <ParagraphSection key={1} {...children[1]?.content} />
        </>
      )}
    </ModalContainer>
  );
};
