import type { MediaIframeDTO } from 'core/domain/Media/MediaIframeDTO';
import type { MediaImageDTO } from 'core/domain/Media/MediaImageDTO';
import type { MediaVideoDTO } from 'core/domain/Media/MediaVideoDTO';
import isNil from 'lodash/isNil';
import { templateAlt } from 'core/utils/getAlt';

export const parseMedia = (
  media: MediaVideoDTO | MediaImageDTO | MediaIframeDTO | undefined | null | { entity: any },
  template?: boolean,
  langcode?: string,
  title?: string
): any => {
  if (isNil(media) || !('entity' in media)) return undefined;

  const { entity } = media;

  if (isNil(entity)) return undefined;

  if (entity.type === 'MediaIframe') {
    if (entity?.urlIframe) {
      return {
        type: 'iframe',
        urlIframe: entity.urlIframe ? entity.urlIframe : false,
        allowFullscreen: entity.allowFullscreen ? entity.allowFullscreen : false,
        allowPopups: entity.allowPopups ? entity.allowPopups : false,
        allowSameorigin: entity.allowSameorigin ? entity.allowSameorigin : false,
        allowScripts: entity.allowScripts ? entity.allowScripts : false,
        preset: entity.preset ? entity.preset : 'horizontal',
      };
    }
    return {
      type: 'iframe',
      src: entity.html?.value,
    };
  }

  if (entity.type === 'MediaVideo') {
    return {
      type: 'video',
      alt: 'Esto es un vídeo',
      src: {
        desktop: entity.desktop?.video.src,
        mobile: entity.mobile?.video.src,
        tablet: entity.tablet?.video.src,
        laptop: entity.laptop?.video.src,
      },
    };
  }

  if ('mediaImage' in entity && entity.type === 'MediaImage') {
    return {
      type: 'image',
      src: {
        desktop: entity.mediaImage?.src ? entity.mediaImage?.src : null,
        mobile: entity.mediaImageMobile?.src
          ? entity.mediaImageMobile?.src
          : entity.mediaImage?.src,
        tablet: entity.mediaImageTablet?.src
          ? entity.mediaImageTablet?.src
          : entity.mediaImage?.src,
        laptop: entity.mediaImageLaptop?.src
          ? entity.mediaImageLaptop?.src
          : entity.mediaImage?.src,
      },
      width: entity.mediaImage?.width + 'px',
      height: entity.mediaImage?.height + 'px',
      alt: template ? templateAlt(title ? title : '', langcode) : entity.mediaImage?.alt,
    };
  }
};
