import { Color, CustomSpacing, Paragraph } from '@genially/public-web-components';

import type { FC } from 'react';
import { parseBackground } from 'core/infrastructure/parseBackground';

export interface BackgroundDTO {
  backgroundType: 'image' | 'video';
  baseColor: {
    color: Color;
  };
  image?: {
    desktop?: ImageSource;
    laptop?: ImageSource;
    tablet?: ImageSource;
    mobile?: ImageSource;
  };
}

interface ImageSource {
  src: string;
}

interface ParagraphParagraphProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
    layout_paragraphs: { parent_uuid: string; region: string };
  };
  paragraph?: { processed: string };
  paragraphType?: string;
  paragraphColor?: Color;
}

export const ParagraphParagraph: FC<ParagraphParagraphProps> = ({
  behaviorSettings,
  paragraph,
  paragraphType,
  paragraphColor,
}) => {
  return (
    <Paragraph
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle?.spacing}
      paragraph={
        paragraph
          ? { text: paragraph.processed, color: paragraphColor, type: paragraphType }
          : undefined
      }
    />
  );
};
