import type { FC } from 'react';
import { ParagraphAccordeon } from 'components/ParagraphAccordeon';
import { ParagraphBanner } from 'components/ParagraphBanner';
import { ParagraphBrands } from 'components/ParagraphBrands';
import { ParagraphButton } from 'components/ParagraphButton';
import { ParagraphCardsGrid } from 'components/ParagraphCardsGrid';
import { ParagraphCardsWithLink } from 'components/ParagraphCardsWithLink';
import { ParagraphColumns } from 'components/ParagraphColumns';
import { ParagraphFullhtml } from 'components/ParagraphFullhtml';
import { ParagraphIcon } from 'components/ParagraphIcon';
import { ParagraphLinks } from 'components/ParagraphLinks';
import { ParagraphList } from 'components/ParagraphList';
import { ParagraphMedia } from 'components/ParagraphMedia';
import { ParagraphModal } from 'components/ParagraphModal';
import { ParagraphParagraph } from 'components/ParagraphParagraph';
import { ParagraphPlay } from 'components/ParagraphPlay';
import { ParagraphPricing } from 'components/ParagraphPricing';
import { ParagraphSection } from 'components/ParagraphSection';
import { ParagraphSticker } from 'components/ParagraphSticker';
import { ParagraphTabsStyle1 } from 'components/ParagraphTabsStyle1';
import { ParagraphTeaser } from 'components/ParagraphTeaser';
import { ParagraphTestimonials } from 'components/ParagraphTestimonials';

const paragraphsMap: Record<string, any> = {
  ParagraphAccordeon,
  ParagraphBanner,
  ParagraphCardsWithLink,
  ParagraphCardsGrid,
  ParagraphSticker,
  ParagraphTeaser,
  ParagraphBrands,
  ParagraphTestimonials,
  ParagraphPricing,
  ParagraphTabsStyle1,
  ParagraphFullhtml,
  ParagraphList,
  ParagraphLinks,
  ParagraphColumns,
  ParagraphSection,
  ParagraphParagraph,
  ParagraphIcon,
  ParagraphButton,
  ParagraphMedia,
  ParagraphModal,
  ParagraphPlay,
  default: (paragraph: Paragraph) => `No component in paragraphsMap for ${paragraph.type}`,
};

export interface Paragraph {
  type: keyof typeof paragraphsMap;
  content: any;
  uuid: string;
}

type Props = {
  paragraphs: Array<Paragraph>;
  langcode: string;
  firstRef?: any;
  fullNode?: any;
};

export const ParagraphsFactory: FC<Props> = ({ paragraphs, langcode, firstRef, fullNode }) => {
  return (
    <>
      {paragraphs.map<any>((paragraph, index) => {
        if (
          paragraph.type === 'ParagraphColumns' ||
          paragraph.type === 'ParagraphSection' ||
          paragraph.type === 'ParagraphModal'
        ) {
          const childParagraphs: any[] = [];
          fullNode.paragraphs.forEach((element: any) => {
            if (
              element.entity.behaviorSettings?.layout_paragraphs?.parent_uuid ===
              paragraph.content.uuid
            ) {
              childParagraphs.push({ type: element.entity.type, content: element.entity });
            }
          });
          paragraph.content.children = childParagraphs;
        }
        if (paragraph.content.behaviorSettings?.layout_paragraphs?.parent_uuid) return;
        if (paragraph.content.hidden) return;

        const Element = paragraphsMap[paragraph.type] || paragraphsMap.default;
        const key = paragraph.type + index;

        return (
          <section
            id={paragraph.content.refId ? paragraph.content.refId : paragraph.type + index}
            data-testid={key}
            key={key}
            {...(index === 0 && { ref: firstRef })}
          >
            <Element first={index === 0} {...paragraph.content} langcode={langcode} />
          </section>
        );
      })}
    </>
  );
};
