import { Color, CustomSpacing, SectionComponent, theme } from '@genially/public-web-components';

import type { FC } from 'react';
import { ParagraphButton } from 'components/ParagraphButton';
import { ParagraphColumns } from 'components/ParagraphColumns';
import { ParagraphIcon } from 'components/ParagraphIcon';
import { ParagraphMedia } from 'components/ParagraphMedia';
import { ParagraphModal } from 'components/ParagraphModal';
import { ParagraphParagraph } from 'components/ParagraphParagraph';
import { ParagraphPlay } from 'components/ParagraphPlay';
import { parseBackground } from 'core/infrastructure/parseBackground';

export interface BackgroundDTO {
  backgroundType: 'image' | 'video';
  baseColor: {
    color: Color;
  };
  image?: {
    desktop?: ImageSource;
    laptop?: ImageSource;
    tablet?: ImageSource;
    mobile?: ImageSource;
  };
}

interface ImageSource {
  src: string;
}

interface ParagraphSectionProps {
  behaviorSettings?: {
    rowStyle: {
      background: BackgroundDTO;
      spacing: CustomSpacing;
      general: any;
      borders: any;
      hovers?: any;
    };
    layout_paragraphs: { layout: string; config: any };
  };
  children?: any;
  link?: { url: { path: string } };
  animation?: keyof typeof theme.animations;
}
// export interface Paragraph {
//   type: keyof typeof paragraphsMap;
//   content: any;
//   uuid: string;
// }

export const ParagraphSection: FC<ParagraphSectionProps> = ({
  behaviorSettings,
  link,
  children,
  animation,
}) => {
  return (
    <SectionComponent
      layout={behaviorSettings?.layout_paragraphs.layout}
      background={parseBackground(behaviorSettings?.rowStyle?.background)}
      customSpacing={behaviorSettings?.rowStyle.spacing}
      borders={behaviorSettings?.rowStyle.borders}
      general={behaviorSettings?.rowStyle.general}
      hovers={behaviorSettings?.rowStyle.hovers}
      link={link}
      animation={animation}
    >
      {children &&
        children.map((paragraph: any, index: number) => {
          if (paragraph.type === 'ParagraphSection') {
            return <ParagraphSection key={index} {...paragraph.content} />;
          }
          if (paragraph.type === 'ParagraphColumns') {
            return <ParagraphColumns key={index} {...paragraph.content} />;
          }
          if (paragraph.type === 'ParagraphIcon') {
            return <ParagraphIcon key={index} {...paragraph.content} />;
          }
          if (paragraph.type === 'ParagraphParagraph') {
            return <ParagraphParagraph key={index} {...paragraph.content} />;
          }
          if (paragraph.type === 'ParagraphButton') {
            return <ParagraphButton key={index} {...paragraph.content} />;
          }
          if (paragraph.type === 'ParagraphMedia') {
            return <ParagraphMedia key={index} {...paragraph.content} />;
          }
          if (paragraph.type === 'ParagraphModal') {
            return <ParagraphModal key={index} {...paragraph.content} />;
          }
          if (paragraph.type === 'ParagraphPlay') {
            return <ParagraphPlay key={index} {...paragraph.content} />;
          }
          // const Element = paragraphsMap[paragraph.type] || paragraphsMap.default;
          // return <Element key={index} first={index === 0} {...paragraph.content} langcode={'en'} />;
        })}
    </SectionComponent>
  );
};

// const paragraphsMap: Record<string, any> = {
//   ParagraphParagraph,
//   ParagraphIcon,
//   ParagraphMedia,
//   ParagraphColumns,
//   ParagraphSection,
//   ParagraphButton,
//   default: (paragraph: Paragraph) => `No component in paragraphsMap for ${paragraph.type}`,
// };
