import { parseFaqs, parseSchemaBreadcrums } from './parsingSchema';

import { checkPerformanceCookiesEnabled } from 'core/utils/cookies';
import { getLCPImageURL } from './LCPUrl';
// eslint-disable-next-line sort-imports
import { Helmet } from 'react-helmet';
import { metaDataService } from 'core/services/metaDataService';

interface SeoProps {
  content: any;
}

export const Seo = ({ content }: SeoProps) => {
  const LCPImage = getLCPImageURL(content);
  let faqsValues;
  let bcs;
  switch (content.bundle) {
    case 'template': {
      bcs = parseSchemaBreadcrums(content.breadcrumb, content.url.path, content.langcode.value);
      break;
    }
    case 'template_category': {
      faqsValues = content.faqs && content.faqs.entity ? content?.faqs?.entity?.items : null;
      bcs = parseSchemaBreadcrums(content.breadcrumb, content.url.path, content.langcode.value);
      break;
    }
    case 'landing': {
      const result = content.paragraphs.filter((obj: any) => {
        return obj.entity.type === 'ParagraphAccordeon';
      });
      faqsValues = result[0] ? result[0].entity.items : null;
      break;
    }
    default: {
      break;
    }
  }
  const FAQS = parseFaqs(faqsValues);
  const links = metaDataService.getLinks(content);
  links?.push({ rel: 'preload', href: LCPImage, as: 'image' });
  const cookiesEnabled = checkPerformanceCookiesEnabled();
  return (
    <Helmet
      htmlAttributes={{
        'lang': content?.langcode?.value,
        'dir': 'ltr',
        'nodeId': content?.id,
        'prefix':
          'content: http://purl.org/rss/1.0/modules/content/  dc: http://purl.org/dc/terms/  foaf: http://xmlns.com/foaf/0.1/  og: http://ogp.me/ns#  rdfs: http://www.w3.org/2000/01/rdf-schema#  schema: http://schema.org/  sioc: http://rdfs.org/sioc/ns#  sioct: http://rdfs.org/sioc/types#  skos: http://www.w3.org/2004/02/skos/core#  xsd: http://www.w3.org/2001/XMLSchema# ',
        'xmlns': 'http://www.w3.org/1999/xhtml',
        'xmlns:fb': 'https://www.facebook.com/2008/fbml',
        'xmlns:og': 'http://ogp.me/ns#',
      }}
      title={metaDataService.getTitle(content)}
      meta={metaDataService.getMetas(content)}
      link={links}
      defer={false}
    >
      <script key="gatsby-vwo">
        {`
          window.VWO = window.VWO || [];
            window.VWO.init = window.VWO.init || function(state) { window.VWO.consentState = state; }
            window.VWO.init(${cookiesEnabled ? 1 : 2});
        `}
      </script>
      <script>
        {`
                /** OptanonWrapper function provided by OnTrust executes upon every change in Cookie Consent.
                 * By Default this function has an empty definition, you need to modify this as below to run VWO SmartCode on the page.
                 */
                function OptanonWrapper() {
                  // Pass the name of the groups in an array in the isConsentGiven function for which you need to check consent 
                  var validGroups = ["C0003"]  // Eg -> ["C0001", "C0002"]
                  function isConsentGiven(validGroups) {
                    return validGroups.every((group) => OnetrustActiveGroups.includes(group));
                  }
                  if (OneTrust.IsAlertBoxClosedAndValid() && isConsentGiven(validGroups)) {
                    window.VWO = window.VWO || [];
                    window.VWO.init = window.VWO.init || function(state) { window.VWO.consentState = state; }
                    window.VWO.init(1);
                  } else if (OneTrust.IsAlertBoxClosedAndValid() && !isConsentGiven(validGroups)) {
                    window.VWO = window.VWO || [];
                    window.VWO.init = window.VWO.init || function(state) { window.VWO.consentState = state; }
                    window.VWO.init(3);
                  } else if (!OneTrust.IsAlertBoxClosedAndValid()) {
                     window.VWO = window.VWO || [];
                    window.VWO.init = window.VWO.init || function(state) { window.VWO.consentState = state; }
                    window.VWO.init(2);
                  }
                }
      `}
      </script>
      {content.bundle === 'template' ? (
        <script className="metasSchema" type="application/ld+json">
          {JSON.stringify(content?.metasSchema)}
        </script>
      ) : (
        <script type="application/ld+json">{content?.metasSchema}</script>
      )}

      {FAQS && (
        <script className="faqs" type="application/ld+json">
          {JSON.stringify(FAQS)}
        </script>
      )}
      {bcs && (
        <script className="breadcrumbs" type="application/ld+json">
          {JSON.stringify(bcs)}
        </script>
      )}
    </Helmet>
  );
};

Seo.defaultProps = {
  language: 'en',
  meta: [],
  link: [],
  schema: {},
};
