import { translate } from 'core/infrastructure/translator';

interface FAQ {
  '@type': string;
  'name': string;
  'acceptedAnswer': {
    text: string;
    type: string;
  };
}

interface BC {
  '@type': string;
  'position': number;
  'name': string;
  'item': string;
}

export function parseFaqs(faqs: Array<any>) {
  if (!faqs || faqs.length <= 0) return null;

  const faqsArr: FAQ[] = [];

  faqs.map((item: any) => {
    faqsArr.push({
      '@type': 'Question',
      'name': item.item.title,
      'acceptedAnswer': {
        text: item?.item?.paragraph?.processed,
        type: 'Answer',
      },
    });
  });

  const data = {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    'mainEntity': faqsArr,
  };

  return data;
}

export function parseSchemaBreadcrums(breadcrumb: Array<any>, url: string, lang: string) {
  if (!breadcrumb || breadcrumb.length <= 0 || breadcrumb === undefined) return null;
  const bcArr: BC[] = [];
  const translateFromContent = (key: string) => translate(lang, key);

  function getCDPurl(lang: string) {
    let url = '/templates/';
    switch (lang) {
      case 'pt-br': {
        url = '/' + lang + '/modelos/';
        break;
      }
      case 'es': {
        url = '/' + lang + '/plantillas/';
        break;
      }
      case 'de': {
        url = '/' + lang + '/vorlagen/';
        break;
      }
      case 'fr': {
        url = '/' + lang + '/modeles/';
        break;
      }
      case 'it': {
        url = '/' + lang + '/templates/';
        break;
      }
      default: {
        url = '/templates/';
        break;
      }
    }
    return url;
  }

  breadcrumb.map((bc: any, i: number) => {
    let finalUrl = bc.url;

    if (i === 0) {
      bcArr.push({
        '@type': 'ListItem',
        'position': i + 1,
        'name': translateFromContent('templates.breadcrumbback'),
        'item': 'https://genially.com' + getCDPurl(lang),
      });
    }

    if (breadcrumb.length === i + 1) {
      finalUrl = url;
    }
    bcArr.push({
      '@type': 'ListItem',
      'position': i + 2,
      'name': bc.title,
      'item': 'https://genially.com' + finalUrl,
    });
  });

  const data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': bcArr,
  };

  return data;
}
